import axios from 'axios';
import Cookies from 'js-cookie';
import { isLocalhost } from '../utils/isLocalhost';
import { encryptText, decryptText } from '../utils/encryptionUtils';

const getAccessToken = () => {
  const encryptedToken = Cookies.get('authKey');
  return encryptedToken ? decryptText(encryptedToken) : null;
};

const getRefreshToken = () => {
  const encryptedToken = Cookies.get('authRefreshKey');
  return encryptedToken ? decryptText(encryptedToken) : null;
};

const getTenantId = () => {
  return Cookies.get('tenantId');
};

const setAccessToken = (accessToken) => {
  const cookieOptions = {
    path: '/',
    domain: isLocalhost() ? '.localhost.com' : `.${process.env.REACT_APP_DOMAIN}`,
  };

  Cookies.set('authKey', encryptText(accessToken), cookieOptions);
};

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    authorization: getAccessToken()
  },
});

const refreshAccessToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    const response = await api.post('/api/auth/refresh-token', {
      refreshToken,
    });
    const newAccessToken = response.data.accessToken;
    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    throw error;
  }
};

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    const tenantId = getTenantId();
    
    // Add access token to authorization header if it exists
    if (accessToken) {
      config.headers.authorization = accessToken;
    }

    // Add tenantId to headers
    if (tenantId) {
      config.headers['X-tenant-Id'] = tenantId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const token = await refreshAccessToken();
        setAccessToken(token);
        originalRequest.headers.authorization = token;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing access token:', refreshError);
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export default api;