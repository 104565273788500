import styles from './Toolbar.module.css';
import { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AuthContext } from '../../context/AuthContext';
import { getRecord } from '../../api/crud';
import getFirstLetters from '../../utils/getFirstLetters';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';

const Toolbar = ({ toggleDrawer }) => {
    const { logout, getUserId } = useContext(AuthContext);
    const userId = getUserId();
    const location = useLocation();
    const [ anchorEl, setAnchorEl ] = useState(null);
    const navigate = useNavigate();

    // get the logged in user profile
    const { data: user, isLoading } = useQuery({
        queryFn: () => getRecord('user', userId),
        queryKey: ['user', userId],
        enabled: !!userId
    });

    const open = Boolean(anchorEl);

    let text = '';

    // Check the current path and set the text accordingly
    if(location.pathname === '/'){
        text = 'Dashboard';
    } else if (location.pathname === '/admin/learners') {
      text = 'Learners';
    } else if (location.pathname === '/admin/users') {
        text = 'Users';
    } else if (location.pathname === '/admin/subjects') {
        text = 'Subjects';
    } else if (location.pathname === '/admin/events') {
        text = 'Events';
    } else if (location.pathname === '/admin/news') {
        text = 'News';
    } else if (location.pathname === '/admin/schools') {
        text = 'School';
    } else if (location.pathname === '/admin/plans') {
        text = 'Plans';
    } else if (location.pathname === '/admin/settings') {
        text = 'Settings';
    }

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOut = () => {
        logout();
    }

    const navigateToSettings = () => {
        navigate('/admin/settings');
    };

    return (
        <nav className={styles['toolbar']}>
            <div className={styles['sidebar-button']}>
                <span onClick={toggleDrawer} className={`${styles['mui-icon']} ${styles['sidebarBtn']}`} ><MenuOutlinedIcon /></span>
                <span className={styles['top-nav-text']}>{text}</span>
            </div>
            <div className={styles['search-box']}>
                <input type="text" placeholder="Search..." />
                <span className={styles['search-icon']} ><SearchOutlinedIcon className={styles['mui-icon']} fontSize="large" /></span>
            </div>
            <div className={styles['profile-details']}>
                {isLoading ? (
                    // Show skeletons while loading
                    <div className={styles['profile-details-loading-skeleton']}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="text" width={120} height={30} />
                    </div>
                ) : (
                    // Show actual user details when loaded
                    <>
                        <Avatar src={user?.picture} alt="profile" />
                        <span className={styles['admin_name']}>{`${getFirstLetters(user?.firstName)} ${user?.lastName}`}</span>
                        <span className={styles['arrow-down']} onClick={handleProfileClick}><ExpandMoreOutlinedIcon className={styles['mui-icon']} /></span>
                    </>
                )}
            </div>
            <Popover
                id="profile-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={styles['nav-popover-content']}>
                    <Typography variant="body2" onClick={navigateToSettings}>Settings</Typography>
                    <Typography onClick={signOut} variant="body2">Logout</Typography>
                </div>
            </Popover>
        </nav>
    );
}

export default Toolbar;