import React from 'react';
import { useMemo, useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../../context/AuthContext';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import getListViewColumnsByName from '../../../objectManager/getListViewColumnsByName';
import { getRecords, getRecordByFilter } from '../../../api/crud';
import { formatDateTime } from '../../../utils/dateUtils';
import { Box, Grid, Paper, Typography } from '@mui/material';
import styles from './css/SchoolDashboard.module.css';
import EventIcon from '@mui/icons-material/Event';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import Face4OutlinedIcon from '@mui/icons-material/Face4Outlined';

const SchoolDashboard = () => {
  // Filter to only retrieve active users
  const staffFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'isActive',
        operator: 'equals',
        value: true
      },
    ]
  };

  // Fetching staff data
  const { data: staff, isLoading } = useQuery({
    queryFn: () => getRecords('users', staffFilter),
    queryKey: ['staff'],
  });

  // Filter to only retrieve published events
  const eventsFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'published',
        operator: 'equals',
        value: true
      },
    ]
  };

  const { data: eventsData/*, isLoading: eventsLoading*/ } = useQuery({
    queryFn: () => getRecords('events', eventsFilter),
    queryKey: ['events-dashboard'],
  });
  const events = eventsData?.slice(0, 10) || []; // Get the first 10 events

  const { getTenantId } = useContext(AuthContext);
  const tenantId = getTenantId();

  const filter = {
    filterLogic: null,
    filters: [
      {
        field: 'tenantId',
        operator: 'equals',
        value: tenantId
      },
    ]
  };
  
  // Get settings data to pull the number of teachers and learners
  const { data: settings/*, isLoading: settingsLoading*/ } = useQuery({
    queryFn: () => getRecordByFilter('home-page', filter),
    queryKey: ['home-page'],
    enabled: !!tenantId,
  });

  const numberOfStudents = settings?.statistics?.students;
  const numberOfTeachers = settings?.statistics?.teachers;

  // Memoize table columns
  const tableColumns = useMemo(() => getListViewColumnsByName('users'), []);

  // Material-React-Table configuration
  const table = useMaterialReactTable({
    columns: tableColumns,
    data: staff || [],
    enableRowActions: false,
    enableRowSelection: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    state: {
      isLoading,
    },
    initialState: {
      showGlobalFilter: false,
      density: 'compact',
    },
    muiTablePaperProps: {
      elevation: 0, // Remove MUI box shadow
      sx: {
        borderRadius: 'var(--border-radius)',
      },
    },
  });

  return (
    <div className={styles['dashboard-container']}>
      <Grid container spacing={3}>
        {/* Left section: Number of students, teachers, and Material React Table */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} className={styles['dashboard-box']}>
                <Typography variant="h6">Number of Students</Typography>
                <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
                  <Typography variant="h4" sx={{ mr: 1 }}>
                    {numberOfStudents || 0}
                  </Typography>
                  <Avatar sx={{ bgcolor: 'var(--green-color)' }}>
                    <LocalLibraryOutlinedIcon />
                  </Avatar>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} className={styles['dashboard-box']}>
                <Typography variant="h6">Number of Teachers</Typography>
                <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
                  <Typography variant="h4">
                    {numberOfTeachers || 0}
                  </Typography>
                  <Avatar sx={{ bgcolor: 'var(--green-color)' }}>
                    <Face4OutlinedIcon />
                  </Avatar>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={3} className={styles['material-react-table']}>
                <Typography sx={{marginBottom: '10px'}}>Staff Members at the School</Typography>
                <MaterialReactTable table={table} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* Right section: Upcoming events */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} className={styles['upcoming-events']}>
            <Typography variant="h6" gutterBottom>
              Upcoming Events
            </Typography>
            {/* Render upcoming events */}
            {events?.length !== 0 ? (
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                }}
              >
                {events?.map((event) => (
                  <ListItemButton key={event?._id}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'var(--orange-color)' }}>
                        <EventIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={event?.title} secondary={formatDateTime(event?.eventDate)} />
                  </ListItemButton>
                ))}
              </List>
              ) : (
                <Typography variant="p" sx={{display: 'flex', justifyContent: 'center'}}>No Events To Display</Typography>
              )
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SchoolDashboard;
