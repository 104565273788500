import styles from './Sidebar.module.css';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { schoolSidebarLinks, techSupportSidebarLinks } from '../../utils/sidebarTabs';

const SidebarItems = ({ isMenuOpen }) => {
  const location = useLocation();
  const { isTechSupportTenant } = useContext(AuthContext);

  const isTechSupport = isTechSupportTenant();
  const sidebarToUse = isTechSupport ? techSupportSidebarLinks : schoolSidebarLinks;

  // Find the active link based on the current location
  const activeLink = sidebarToUse.find(
    (link) => location.pathname === link.path
  );

  const handleLogoutClick = () => {};

  return (
    <ul className={styles['nav-links']}>
      {sidebarToUse.map((link, index) => (
        <li className={styles[link.listItemClass]} key={index}>
          {link.text === 'Log Out' ? (
            <div className={styles['log-out']} onClick={handleLogoutClick}>
              <span className={styles['mui']}>{link.icon}</span>
              {isMenuOpen && <span className={styles['links_name']}>{link.text}</span>}
            </div>
          ) : (
            // For other links, no onClick handler
            <Link
              to={link.path}
              className={
                activeLink && activeLink.path === link.path ? styles['active'] : ''
              }
            >
              <span className={styles['mui']}>{link.icon}</span>
              <span className={styles['links_name']}>{link.text}</span>
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default SidebarItems;
