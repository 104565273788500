import styles from './css/Login.module.css';
import { useState, useContext } from 'react';
import { login as loginEndpoint } from '../../api/authentication';
import { AuthContext } from '../../context/AuthContext';
import { isLocalhost, getProtocol } from '../../utils/isLocalhost';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import SEO from '../../components/seo/SEO';
import Copyright from '../../components/copyright/Copyright';

const initialForm = {
  email: '',
  password: ''
};

const Login = () => {
  const { login } = useContext(AuthContext);
  const [formSubmitErrors, setFormSubmitErrors] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginForm, setLoginForm] = useState(initialForm);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await loginEndpoint(loginForm);
      response.subDomain = response.subDomain || 'app';
      login(response);

      setIsSubmitting(false);
      setLoginForm(initialForm); // Reset form values

      // Redirect to the user's subdomain
      const subdomain = response.subDomain || 'app';
      const port = isLocalhost() ? window.location.port : '';
      const mainDomain = isLocalhost() ? `localhost.com:${port}` : process.env.REACT_APP_DOMAIN;
      window.location.replace(`${getProtocol()}://${subdomain}.${mainDomain}`);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setFormSubmitErrors(error?.response?.data.message);
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setLoginForm({
      ...loginForm,
      [name]: value,
    });
  };

  return (
    <>
      <SEO
        title='Login | EduTrack'
        description='Login page to EduTrack Content Management Tool'
      />
      <div className={styles['container']}>
        <div className={styles['company-info']}>
          <Link href="https://edutrack.co.za/" variant="body2">
            Edutrack
          </Link>
        </div>
        <div className={styles['login-form-container']}>
          <Avatar sx={{ m: 1, bgcolor: 'var(--primary-color)' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} className={styles['form']}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={loginForm.email}
              onChange={handleFormChange}
              error={!!formSubmitErrors}
              helperText={formSubmitErrors ? " " : ""}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={loginForm.password}
              onChange={handleFormChange}
              error={!!formSubmitErrors}
              helperText={formSubmitErrors ? " " : ""}
            />
            <div className={styles['error-container']}>
              {formSubmitErrors && (
                <small className={styles['error']}>
                  {formSubmitErrors}
                </small>
              )}
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={styles['button']}
              disabled={isSubmitting}
            >
              Sign In
            </Button>
            <Grid sx={{ textAlign: 'center' }}>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
          <div className={styles['copyright']}>
            <Copyright />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;